<template>
  <div>
    <div class="container-fluid center-max-1320-px mt-3 mb-5 p-3">
      <div class="row flex-nowrap justify-content-center">
        <div class="col-md-5">
          <h2 class="text-center p-4">Login</h2>
          <form @submit.prevent="submit">
            <div class="form-group">
              <input
                id="username"
                v-model="form.username.value"
                type="text"
                required=""
                pattern=".{3,}"
                title="3 characters minimum"
                placeholder="Username"
                class="payment-form-control"
              />

              <input
                id="password"
                v-model="form.password.value"
                type="password"
                required=""
                pattern=".{8,}"
                title="8 characters minimum"
                placeholder="Password"
                class="payment-form-control"
              />

              <input
                type="submit"
                class="login-submit-button"
                value="LOGIN"
                :disabled="isLoggingIn"
              />
            </div>
          </form>
        </div>
      </div>

      <div v-if="loginFailed" class="row flex-nowrap justify-content-center">
        <div class="col-md-5 alert alert-danger alert-dismissible" role="alert">
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            @click="loginFailed = false"
          >
            <span aria-hidden="true">×</span>
          </button>
          Invalid credentials
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useForm } from '@/app/use/validation';
import { loginRequest } from '@/app/api/auth';
import { useUser } from '@/app/use/user';

export default {
  name: 'Login',
  inheritAttrs: false,
  setup() {
    const isLoggingIn = ref(false);
    const loginFailed = ref(false);

    const { submit, form, useField } = useForm({
      onSubmit() {
        isLoggingIn.value = true;
        loginRequest({
          username: form.value.username.value,
          password: form.value.password.value,
        })
          .then(response => {
            const { login } = useUser();
            login(response);
            return response;
          })
          .catch(() => {
            loginFailed.value = true;
          })
          .finally(() => (isLoggingIn.value = false));
      },
    });

    useField('username', {
      rules: {
        required: value => ({
          valid: value !== null && value.length >= 3,
          message: 'Please provide valid username',
        }),
      },
    });

    useField('password', {
      rules: {
        required: value => ({
          valid: value !== null && value.length >= 8,
          message: 'Password should be at least 8 characters long',
        }),
      },
    });

    return { submit, isLoggingIn, loginFailed, form };
  },
};
</script>
